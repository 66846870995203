import React from 'react';
import { tv } from 'tailwind-variants';
import type { PropsWithChildren } from 'react';
import { type VariantProps, cn } from '#pie/utils/TailwindUtils';

export interface DescriptionListItemProps extends VariantProps<typeof styles>, PropsWithChildren {
  children: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  full?: boolean;
  title: string;
}

export function DescriptionListItem({
  children,
  direction = 'horizontal',
  full = false,
  title,
}: DescriptionListItemProps) {
  const Wrapper = direction === 'horizontal' ? React.Fragment : 'div';
  const s = styles({ direction });

  return (
    <Wrapper>
      <dt className={cn(s.descriptionTerm(), full && 'col-span-full')} aria-label={title}>
        {title}
      </dt>
      <dd className={cn(s.descriptionDetail(), full && 'col-span-full')} aria-label={`Definition of ${title}`}>
        {children}
      </dd>
    </Wrapper>
  );
}

const styles = tv({
  slots: {
    descriptionDetail: 'text-neutral-600',
    descriptionTerm: 'font-bold',
  },
  variants: {
    direction: {
      horizontal: {
        descriptionDetail: 'flex-start -mt-5 flex xl:mt-0',
        descriptionTerm: 'min-w-[14rem]',
      },
      vertical: {
        descriptionDetail: 'flex-start',
        descriptionTerm: '',
      },
    },
  },
});
