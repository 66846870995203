import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getCprContact, getCprContactFilters, useGetCprContact } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { CprOrganisationDetailExport } from '#pie/components/cpr-export/CprOrganisationDetailExport';
import { DataFilters } from '#pie/components/data-filters/DataFilters';
import { DataTable } from '#pie/components/data-table/DataTable';
import { DataToolbar } from '#pie/components/data-toolbar/DataToolbar';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { useDataView } from '#pie/hooks/useDataView';
import { cprOrganisationDetailFiltersAtom, cprOrganisationDetailSortingAtom } from '#pie/stores/cprOrganisationDetail';
import { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';

export const ContactPersonRegisterOrganisationDetail = () => {
  const { t } = useTranslation();
  const organisationId = useParams()['organisationId']!;
  const { data } = useGetCprContact({
    filter: { organisationIds: [organisationId!] },
    pagination: { pageIndex: 0, pageSize: 1 },
  });
  const organisationTitle = data?.items[0].organisation.name;

  const { tableProps, filterProps, toolbarProps } = useDataView({
    columns: helper => [
      helper.accessor('contactGroup.label', {
        header: t('common.contact_group'),
      }),
      helper.accessor('name', {
        header: t('common.name'),
      }),
      helper.accessor('email', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`mailto:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.email'),
      }),
      helper.accessor('phone', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`tel:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.phone'),
      }),
    ],
    dateKeys: [],
    filters: [
      {
        id: 'contactGroupIds',
        label: t('common.contact_group'),
        options: data => mapToFilterOptions(data?.contactGroups),
      },
    ],
    filtersAtom: cprOrganisationDetailFiltersAtom,
    filtersQuery: getCprContactFilters,
    hardFilters: { organisationIds: [organisationId] },
    query: getCprContact,
    sortingAtom: cprOrganisationDetailSortingAtom,
  });

  return (
    <>
      <PageHead
        title={t('cpr_organisation_detail_overview.header.title', {
          organisation: organisationTitle,
        })}
      />
      <Page>
        <PageHeader
          title={t('cpr_organisation_detail_overview.header.title', {
            organisation: organisationTitle,
          })}
          className="mb-8"
          backHref={`../organisatie`}
          previousText={t('common.button.back')}
        >
          <CprOrganisationDetailExport />
        </PageHeader>
        <div className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <DataToolbar {...toolbarProps} />
              <DataTable {...tableProps} idKey="id" />
            </Stack>
          </Card>
          <DataFilters {...filterProps} />
        </div>
      </Page>
    </>
  );
};
