import { Arrow, Content, Portal, Root, Trigger } from '@radix-ui/react-tooltip';
import type { PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  message: ReactNode;
}

export const Tooltip = ({ children, message, ...props }: Props) => (
  <Root delayDuration={0} {...props}>
    <Trigger className="cursor-default" type="button">
      {children}
    </Trigger>
    <Portal>
      <Content className="data-[side='left']:animate-slide-left data-[side='top']:animate-slide-up data-[side='bottom']:animate-slide-down data-[side='right']:animate-slide-right relative z-30 mb-1 max-w-md rounded-lg bg-white px-4 py-2 drop-shadow-[0_0_1px_#94A3B8]">
        {message}
        <Arrow className="text-white" />
      </Content>
    </Portal>
  </Root>
);
