import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { Divider } from '#pie/components/divider/Divider';
import { Icon } from '#pie/components/icon/Icon';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { getProductKindIcon, getProvidedServiceIcon } from '#pie/utils/textToIcon';

interface MarketPartyRegistrationDetailsProps {
  marketParty: MarketPartyRegistrationDto;
}

export const MarketPartyRegistrationDetails = ({ marketParty }: MarketPartyRegistrationDetailsProps) => {
  const { t } = useTranslation('marketParty');
  const showMarketActivitiesProduct = useUserHasRole({ marketRole: ['LV', 'MV', 'PV', 'GDS'] });
  const showMarketActivitiesService = useUserHasRole({ marketRole: ['CSP', 'BSP'] });

  const filterUniqueServiceCategories = () => {
    const seen = new Set();
    return marketParty.serviceCategories?.filter(
      ({ product, providedService }) =>
        !seen.has(`${product}-${providedService}`) && seen.add(`${product}-${providedService}`)
    );
  };

  return (
    <Card
      size="md"
      heading={
        <Text as="h2" variant="h6" className="text-primary-dark">
          {t('title')}
        </Text>
      }
      shadow={true}
    >
      <Stack gap="md" className="mx-auto mb-4 max-w-4xl">
        <DescriptionList variant="grid">
          <DescriptionListItem direction="vertical" title={t('registration_detail.statutory_name')}>
            {`${marketParty.statutoryName} (${t(`marketParty.marketRole.${marketParty.marketRole}_abbreviations`, {
              defaultValue: '',
            })})`}
          </DescriptionListItem>
          <DescriptionListItem direction="vertical" title={t('registration_detail.market_role')}>
            {`${t(`marketParty.marketRole.${marketParty.marketRole}_abbreviations`, {
              defaultValue: '',
            })} (${t(`marketParty.marketRole.${marketParty.marketRole}`, {
              defaultValue: '',
            })})`}
          </DescriptionListItem>
          <DescriptionListItem direction="vertical" title={t('registration_detail.participant_eancode')}>
            {marketParty.marketParticipantEanCode}
          </DescriptionListItem>
          <DescriptionListItem direction="vertical" title={t('registration_detail.state')}>
            {t(`marketParty.status.${marketParty.status}`, {
              defaultValue: '-',
            })}
          </DescriptionListItem>
          <DescriptionListItem direction="vertical" title={t('registration_detail.startdate')}>
            {marketParty.marketParticipantStartDate &&
              getFormattedDate(new Date(marketParty.marketParticipantStartDate), false)}
          </DescriptionListItem>
        </DescriptionList>
      </Stack>

      {(showMarketActivitiesProduct || showMarketActivitiesService) && (
        <Stack gap="md" className="mx-auto mb-2 max-w-4xl">
          <Divider />
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('registration_detail.market_activities')}
          </Text>
        </Stack>
      )}

      {filterUniqueServiceCategories()?.map(
        serviceCategory =>
          (showMarketActivitiesProduct && (
            <Stack gap="sm" className="mx-auto max-w-4xl">
              <section className="bg-whiteborder-b mt-2 rounded-lg border border-neutral-300 p-4">
                <Stack direction="row" gap="xs" align="center">
                  {getProductKindIcon(serviceCategory.product) && (
                    <Icon icon={getProductKindIcon(serviceCategory.product)!} size={24} className="text-primary-dark" />
                  )}
                  <Text className="text-primary-dark font-bold">
                    {t(`marketParty.product.${serviceCategory?.product}`, {
                      defaultValue: '',
                    })}{' '}
                    {t(`serviceCategory.marketSegment.${serviceCategory?.marketSegment}`, {
                      defaultValue: '',
                    })}
                  </Text>
                </Stack>
              </section>
            </Stack>
          )) ||
          (showMarketActivitiesService && (
            <Stack gap="sm" className="mx-auto max-w-4xl">
              <section className="bg-whiteborder-b mt-2 rounded-lg border border-neutral-300 p-4">
                <Stack direction="row" gap="xs" align="center">
                  {getProvidedServiceIcon(serviceCategory.providedService) && (
                    <Icon
                      icon={getProvidedServiceIcon(serviceCategory.providedService)!}
                      size={24}
                      className="text-primary-dark"
                    />
                  )}
                  <Text className="text-primary-dark font-bold">
                    {t(`marketParty.service.${serviceCategory?.providedService}`, {
                      defaultValue: '',
                    })}
                  </Text>
                </Stack>
              </section>
            </Stack>
          ))
      )}
    </Card>
  );
};
