import spinnerIcon from '@iconify/icons-material-symbols/progress-activity';
import { useTranslation } from 'react-i18next';
import { useGetMarketpartyRegistryMarketpartyRegistration } from '#edsn/api/pie-bff';
import { MarketPartyCompany } from './MarketPartyCompany';
import { MarketPartyInvoiceContact } from './MarketPartyInvoiceContact';
import { MarketPartyLicense } from './MarketPartyLicense';
import { MarketPartyPreferredParties } from './MarketPartyPreferredParties';
import { MarketPartyRegistrationDetails } from './MarketPartyRegistrationDetails';
import { MarketPartySubRegistration } from './MarketPartySubRegistration';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { Icon } from '#pie/components/icon/Icon';
import { Page } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

export const MarketPartyRegistration = () => {
  const { t } = useTranslation('marketParty');
  const { data, isLoading } = useGetMarketpartyRegistryMarketpartyRegistration();

  const showLicense = useUserHasRole({ marketRole: ['GDS', 'CSP', 'BSP', 'PV', 'LV', 'MV'] });
  const showInvoiceContact = useUserHasRole({ marketRole: ['GDS', 'LV', 'RNB', 'LNB'] });
  const showPreferredParties = useUserHasRole({ marketRole: ['LV', 'PV'] });

  return (
    <>
      <PageHead title={t('title')} />
      <Page>
        <Stack gap="md" className="mx-auto mb-6 max-w-4xl">
          <Stack gap="md" className="mx-6">
            <Text as="h2" variant="h5">
              {t('title')}
            </Text>
            <Text variant="base">{t('description')}</Text>
          </Stack>
        </Stack>
        <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
          {!!isLoading && (
            <Icon
              role="progressbar"
              aria-label="loading"
              icon={spinnerIcon}
              size={48}
              className="m-auto animate-spin text-neutral-500 opacity-50"
            />
          )}
          {data && !isLoading && (
            <>
              <MarketPartyCompany marketParty={data} />
              {showLicense && <MarketPartyLicense marketParty={data} />}
              <MarketPartyRegistrationDetails marketParty={data} />
              {showInvoiceContact && <MarketPartyInvoiceContact marketParty={data} />}
              {showPreferredParties && <MarketPartyPreferredParties marketParty={data} />}
              <MarketPartySubRegistration marketParty={data} />
            </>
          )}
        </Stack>
      </Page>
    </>
  );
};
