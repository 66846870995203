/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type ProvidedService = (typeof ProvidedService)[keyof typeof ProvidedService];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProvidedService = {
  ANT: 'ANT',
  MTG: 'MTG',
  AFD: 'AFD',
  PRG: 'PRG',
  RDP: 'RDP',
  CPB: 'CPB',
  LVR: 'LVR',
  FCR: 'FCR',
  AFRR: 'AFRR',
  MARI: 'MARI',
  EMRGP: 'EMRGP',
} as const;
