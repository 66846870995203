import IconDelete from '@iconify/icons-material-symbols/delete-outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getManagementContact, useDeleteCprManagementContactContactId } from '#edsn/api/pie-bff';
import type { ContactDto } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { useToast } from '#pie/components/toast/ToastContext';

interface RemoveContactPersonButtonProps {
  data: ContactDto;
}

export const RemoveCprContactpersonButton = ({ data }: RemoveContactPersonButtonProps) => {
  const { addToast } = useToast();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useDeleteCprManagementContactContactId({
    mutation: {
      onError: error => {
        addToast({
          message: `${tcom('toast_server_error')}${error.description}`,
          title: `${tom('cpr_management_detail.error.delete_contactperson')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'delete_contactperson', {});
        addToast({
          message: `${tcom('cpr_management_detail.success.message', { name: data.fullName })}`,
          title: `${tom('delete.title.deleted_contactperson')}`,
          type: 'success',
        });
        setOpenDeleteDialog(false);
        /* c8 ignore start */
        queryClient.invalidateQueries({
          predicate: m => {
            return m.queryKey.some(n => typeof n === 'string' && n.includes(getManagementContact.name));
          },
        });
        /* c8 ignore end */
        queryClient.invalidateQueries(['/cpr-management-contact/contact']);
      },
    },
  });

  return (
    <>
      <Button
        iconStart={IconDelete}
        variant="link"
        onClick={() => setOpenDeleteDialog(true)}
        className="ml-auto"
        aria-label="delete-contactperson"
      />
      <Dialog
        title={tom('delete.title.delete_contactperson')}
        open={openDeleteDialog}
        onOpenChange={setOpenDeleteDialog}
      >
        <Stack gap="lg">
          <Text>{tom('delete.message.delete_user', { name: data.fullName })}</Text>
          <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setOpenDeleteDialog(false);
              }}
            >
              {tom('delete.button.cancel.keep_contactperson')}
            </Button>
            <Button isLoading={isLoading} variant="danger" onClick={() => mutate({ id: data.id })}>
              {tom('delete.title.delete_contactperson')}
            </Button>
          </div>
        </Stack>
      </Dialog>
    </>
  );
};
