import IconCable from '@iconify/icons-material-symbols/cable';
import IconCellWifi from '@iconify/icons-material-symbols/cell-wifi';
import IconContactPage from '@iconify/icons-material-symbols/contact-page-outline';
import IconHotelClass from '@iconify/icons-material-symbols/hotel-class-outline';
import IconLightbulb from '@iconify/icons-material-symbols/lightbulb-outline';
import IconList from '@iconify/icons-material-symbols/list';
import IconLocalLibrary from '@iconify/icons-material-symbols/local-library-outline';
import IconSettings from '@iconify/icons-material-symbols/manage-accounts';
import IconRequestPage from '@iconify/icons-material-symbols/request-page';
import IconSchool from '@iconify/icons-material-symbols/school-outline';
import { type AccountAuthorisationDto, AuthorizationRoles } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import type { Menu } from './SidebarMenu';
import { TicketCounter } from '#pie/components/ticket-counter/TicketCounter';

export const isLinkBeta = (link: Menu['to']) => !link.includes('http');

export const isMenuItemAllowed = (menuItem: Menu, currentAccount?: AccountAuthorisationDto): boolean => {
  const isMenuUnrestricted = !menuItem.allowedRoles;
  const userHasRole =
    currentAccount?.roles && menuItem.allowedRoles && hasRole(menuItem.allowedRoles, currentAccount.roles);

  const marketRoleCheck =
    !menuItem.allowedMarketRoles ||
    (currentAccount?.marketRole && menuItem.allowedMarketRoles.some(role => currentAccount.marketRole.includes(role)));

  return isMenuUnrestricted || (!!userHasRole && !!marketRoleCheck);
};

export const menu = ({ ticketFilterParams }: { ticketFilterParams?: string }): { main: Menu[]; bottom: Menu[] } => {
  const mainMenuItems: Menu[] = [
    {
      adornment: isOpen => <TicketCounter isOpen={isOpen} />,
      allowedRoles: [AuthorizationRoles.Tickets],
      description: 'Bekijk en beheer tickets',
      exactMatch: false,
      icon: IconList,
      label: 'Tickets',
      to: `/tickets${ticketFilterParams ?? ''}`,
    },
    {
      allowedRoles: [AuthorizationRoles.Tickets],
      description: 'Bekijk de ticket feedback',
      exactMatch: false,
      icon: IconHotelClass,
      label: 'Ticket feedback',
      to: `/ticketfeedback`,
    },
    {
      allowedRoles: [AuthorizationRoles.KnowledgeArticles],
      description: 'Vind antwoorden op je vragen',
      exactMatch: true,
      icon: IconSchool,
      label: 'Kennisbank',
      to: '/kennisbank',
    },
    {
      description: 'Lees het laatste nieuws',
      exactMatch: false,
      icon: IconLocalLibrary,
      label: 'Nieuws',
      to: '/nieuws',
    },
    {
      description: 'Hoe zou jij partners in energie verbeteren?',
      exactMatch: false,
      icon: IconLightbulb,
      label: 'Ideeën',
      to: 'https://mijn.partnersinenergie.nl/nl-NL/ideeen/',
    },
    {
      allowedRoles: [AuthorizationRoles.CprRead],
      description: 'Bekijk de contactpersonen',
      exactMatch: false,
      icon: IconContactPage,
      label: 'Contactpersonen-register',
      to: '/contactpersonenregister',
    },
    {
      allowedMarketRoles: ['LV', 'GDS', 'RNB', 'LNB'],
      allowedRoles: [AuthorizationRoles.CprRead, AuthorizationRoles.SuperUser],
      description: 'Bekijk factuurcontactgegevens',
      exactMatch: false,
      icon: IconRequestPage,
      label: 'Factuurcontact-register',
      to: '/factuurcontactregister',
    },
    {
      allowedRoles: [AuthorizationRoles.Tickets],
      description: 'Bekijk informatie over de aansluitingen',
      exactMatch: false,
      icon: IconCable,
      label: 'Aansluitingsinfo',
      to: '/aansluitingsinformatie',
    },
    {
      allowedRoles: [AuthorizationRoles.DataRequest],
      description: 'Vraag data aan',
      exactMatch: false,
      icon: IconCellWifi,
      label: 'Datadiensten',
      to: '/datadiensten',
    },
  ];

  return {
    bottom: [
      {
        allowedRoles: [AuthorizationRoles.SuperUser, AuthorizationRoles.CprAdmin, AuthorizationRoles.OrganisationAdmin],
        description: 'Beheer jouw organisatie',
        exactMatch: false,
        icon: IconSettings,
        label: 'Beheer',
        to: `/beheer`,
      },
    ],
    main: mainMenuItems,
  };
};
