import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ElementType, ReactNode } from 'react';
import { cn, tv } from '#pie/utils/TailwindUtils';

export type CardProps<TComponent extends ElementType = 'section'> = {
  heading?: ReactNode;
  children: ReactNode;
  className?: string;
  headingUnderline?: boolean;
} & PolymorphicProps<TComponent, VariantProps<typeof styles>>;

export function Card<TComponent extends ElementType = 'section'>({
  children,
  className,
  heading,
  hasBorder,
  shadow = true,
  size,
  headingUnderline = true,
}: CardProps<TComponent>) {
  const s = styles({ hasBorder, headingUnderline, shadow, size });
  return (
    <section className={cn(s.base(), className)}>
      {heading && <header className={s.heading()}>{heading}</header>}
      {children}
    </section>
  );
}

const styles = tv({
  base: 'rounded-lg border border-neutral-300 bg-white',
  slots: {
    heading: 'max-h-14 mb-4',
  },
  variants: {
    hasBorder: {
      error: 'border-l-error border-l-4',
      success: 'border-l-success border-l-4',
    },
    headingUnderline: {
      false: {
        heading: '',
      },
      true: {
        heading: 'border-b border-neutral-200',
      },
    },
    shadow: {
      true: {
        base: 'shadow',
      },
    },
    size: {
      lg: {
        base: 'p-6 pb-2',
        heading: 'pt-4 -mt-6',
      },
      md: {
        base: 'p-6',
        heading: '-mt-6 py-4',
      },
    },
  },
});
