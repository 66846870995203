import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Stack } from '#pie/components/stack/Stack';
import { StatusTag } from '#pie/components/status-tag/StatusTag';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';

interface MarketPartySubRegistrationProps {
  marketParty: MarketPartyRegistrationDto;
}

export const MarketPartySubRegistration = ({ marketParty }: MarketPartySubRegistrationProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="lg"
      heading={
        <Stack direction="row" gap="sm" align="center">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('sub_registration.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="subregistration(s)" size={20}>
            {t('sub_registration.title.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      headingUnderline={false}
    >
      {marketParty.subUnits
        ?.sort((a, b) => {
          if (a.eanCode === marketParty.marketParticipantEanCode) return -1;
          if (b.eanCode === marketParty.marketParticipantEanCode) return 1;
          return 0;
        })
        .map((subUnit, index) => (
          <Stack gap="sm" className="mb-4">
            <Card
              key={index}
              size="md"
              heading={
                <Stack direction="row" align="center" className="justify-between">
                  <Text as="h2" variant="base" className="text-primary-dark font-bold">
                    {subUnit.name}
                  </Text>
                  {subUnit.eanCode === marketParty.marketParticipantEanCode && (
                    <StatusTag status="closed">{t('primary_subregistration.tag')}</StatusTag>
                  )}
                </Stack>
              }
              shadow={false}
            >
              <DescriptionList variant="grid">
                <DescriptionListItem direction="vertical" title={t('sub_registration.name')}>
                  {subUnit.name}
                </DescriptionListItem>
                <DescriptionListItem direction="vertical" title={t('sub_registration.eancode')}>
                  {subUnit.eanCode}
                </DescriptionListItem>
                <DescriptionListItem direction="vertical" title={t('sub_registration.vat')}>
                  {subUnit.vat || '-'}
                </DescriptionListItem>
                <DescriptionListItem direction="vertical" title={t('sub_registration.iban')}>
                  {subUnit.iban || '-'}
                </DescriptionListItem>
                <DescriptionListItem direction="vertical" title={t('startdate')}>
                  {subUnit.startDate && getFormattedDate(new Date(subUnit.startDate), false)}
                </DescriptionListItem>
                <DescriptionListItem direction="vertical" title={t('enddate')}>
                  {(subUnit.endDate && getFormattedDate(new Date(subUnit.endDate), false)) || 'Onbepaald'}
                </DescriptionListItem>
              </DescriptionList>
            </Card>
          </Stack>
        ))}
    </Card>
  );
};
