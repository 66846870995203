import spinnerIcon from '@iconify/icons-material-symbols/progress-activity';
import WarningIcon from '@iconify/icons-material-symbols/warning';
import he from 'he';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import type { InvoiceContactDetailsDto } from '#edsn/api/pie-bff';
import { useGetInvoiceContacts, useGetMarketpartyRegistryInvoiceContactMarketParties } from '#edsn/api/pie-bff';
import { ErrorPage } from '../error-page/ErrorPage';
import { Card } from '#pie/components/card/Card';
import { DescriptionList, DescriptionListItem } from '#pie/components/description-list/DescriptionList';
import { Icon } from '#pie/components/icon/Icon';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Message } from '#pie/components/message/Message';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

const decodeHtmlEntities = (text: string | null | undefined) => {
  return text ? he.decode(text) : '';
};

export const InvoiceContactRegisterDetail = () => {
  const { t } = useTranslation();
  const marketPartyId = useParams()['marketPartyId']!;
  const organisationId = useParams()['organisationId']!;
  const { data, isLoading } = useGetInvoiceContacts(marketPartyId, {
    mprIdOrganization: organisationId,
  });

  const { data: marketParty } = useGetMarketpartyRegistryInvoiceContactMarketParties();
  const marketPartyTitle = marketParty?.items.find(n => n.mprId === marketPartyId)?.name;

  const invoiceContacts = data || [];
  const isForeignParty = invoiceContacts.some(contact => contact.registrationNumberOrigin !== 'NL');

  if (!isLoading && !data) {
    return (
      <ErrorPage
        heading={t('invoice_contact_register_detail.error_page.not_found.title')}
        message={t('invoice_contact_register_detail.error_page.not_found.message')}
      />
    );
  }

  return (
    <>
      <PageHead
        title={t('invoice_contact_register_detail.title', {
          marketParty: marketPartyTitle,
        })}
      />
      <Page>
        <PageHeader
          title={t('invoice_contact_register_detail.title', {
            marketParty: marketPartyTitle,
          })}
          className="mb-8"
          backHref={`../`}
          previousText={t('invoice_contact_register_detail.button_back')}
        />
        {isForeignParty && (
          <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
            <Message variant="error" icon={WarningIcon}>
              {t('invoice_contact_register_detail.error_message_foreign_parties')}
            </Message>
          </Stack>
        )}

        <Stack gap="md" className="mx-auto mb-6 max-w-4xl">
          {isLoading ? (
            <Icon
              role="progressbar"
              aria-label="loading"
              icon={spinnerIcon}
              size={48}
              className="m-auto flex animate-spin text-neutral-500 opacity-50"
            />
          ) : (
            invoiceContacts.map(contact => (
              <Card
                size="lg"
                heading={
                  <Stack direction="row" gap="sm" align="center">
                    <Text as="h2" variant="h6" className="text-primary-dark">
                      {t('invoice_contact.title')}
                    </Text>
                    <InfoTooltip className="text-primary-dark" aria-label="authorization tooltip" size={20}>
                      {t('invoice_contact.tooltip')}
                    </InfoTooltip>
                  </Stack>
                }
                headingUnderline={false}
              >
                <Stack gap="sm" className="mb-4">
                  <Card
                    size="md"
                    heading={
                      <Stack direction="row" gap="xs" align="center">
                        <Text as="h2" variant="base" className="text-primary-dark font-bold">
                          {t('common.subject')}: {contact.subjectDescription}
                        </Text>
                      </Stack>
                    }
                    shadow={false}
                  >
                    {isForeignParty ? (
                      <Text className="text-neutral-600">
                        {t('invoice_contact_register_detail.text_foreign_parties_before')}
                        <ContactPersonLink contact={contact} />
                        {t('invoice_contact_register_detail.text_foreign_parties_after')}
                      </Text>
                    ) : (
                      !isLoading && (
                        <DescriptionList variant="grid">
                          <DescriptionListItem direction="vertical" title={t('invoice_contact.invoice_adress')}>
                            <Text>{contact.statutoryName}</Text>
                            <Text>{'t.a.v. ' + (decodeHtmlEntities(contact.addressExtensionDescription) || '-')}</Text>
                            <Text>
                              {contact.streetName} {contact.houseNumber}
                              {contact.houseNumberExtension}
                            </Text>
                            <Text>
                              {contact.zipCode} {''}
                              {contact.city}
                            </Text>
                            <Text>{contact.country}</Text>
                          </DescriptionListItem>
                          <div className="flex flex-col gap-4">
                            <DescriptionListItem direction="vertical" title={t('invoice_contact.invoice_email')}>
                              {contact.electronicAddressPrimary || '-'}
                            </DescriptionListItem>
                            {contact?.subjectDescription?.includes('Verrekenen meetfouten') && (
                              <DescriptionListItem direction="vertical" title={t('common.contactperson')}>
                                <Stack direction="row" gap="xs" align="center">
                                  <ContactPersonLink contact={contact} />
                                  <InfoTooltip
                                    className="text-primary-dark"
                                    aria-label="authorization tooltip"
                                    size={16}
                                  >
                                    {t('invoice_contact.contactperson.tooltip')}
                                  </InfoTooltip>
                                </Stack>
                              </DescriptionListItem>
                            )}
                          </div>
                        </DescriptionList>
                      )
                    )}
                  </Card>
                </Stack>
              </Card>
            ))
          )}
        </Stack>
      </Page>
    </>
  );
};

const ContactPersonLink = ({ contact }: { contact: InvoiceContactDetailsDto }) => {
  const { t } = useTranslation();

  return (
    <Link
      className={'text-primary link'}
      to={`/contactpersonenregister/contactgroep/${contact.subjectId}?filters=${btoa(
        JSON.stringify({ marketPartyIds: [contact.accountId] })
      )}`}
      aria-label={t('common.contactperson')}
    >
      {contact?.subjectDescription}
    </Link>
  );
};
