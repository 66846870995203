import * as Sentry from '@sentry/react';

export const initSentry = (options: Sentry.BrowserOptions, maskAllText = true) =>
  Sentry.init({
    environment: import.meta.env.MODE,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText })],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0,
    ...options,
  });
