import type { ProvidedService } from '#edsn/api/pie-bff';
import { Icon } from '../icon/Icon';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import { LicenseName } from './LicenseName';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { getIconByText, getProvidedServiceIcon } from '#pie/utils/textToIcon';

export interface HeadingByLicenseProps {
  name?: string | null;
  providedService?: ProvidedService;
}

export const HeadingByLicense = ({ name, providedService }: HeadingByLicenseProps) => {
  const bspOrCsp = useUserHasRole({ marketRole: ['BSP', 'CSP'] });

  if (!name) {
    return null;
  }

  if (bspOrCsp) {
    return (
      <Stack direction="row" gap="xs" align="center">
        {getProvidedServiceIcon(providedService) && (
          <Icon icon={getProvidedServiceIcon(providedService)!} size={24} className="text-primary-dark" />
        )}
        <Text as="h2" variant="base" className="text-primary-dark font-bold">
          <LicenseName name={name} providedService={providedService} />
        </Text>
      </Stack>
    );
  } else {
    return (
      <Stack direction="row" gap="xs" align="center">
        {name && getIconByText(name) && <Icon icon={getIconByText(name)!} size={24} className="text-primary-dark" />}
        <Text as="h2" variant="base" className="text-primary-dark font-bold">
          <LicenseName name={name} providedService={providedService} />
        </Text>
      </Stack>
    );
  }
};
