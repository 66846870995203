import bspFrcIcon from '@iconify/icons-material-symbols/balance';
import bspAffrIcon from '@iconify/icons-material-symbols/battery-change-outline';
import boltIcon from '@iconify/icons-material-symbols/bolt-outline';
import contractIcon from '@iconify/icons-material-symbols/contract-outline';
import bspEmrgpIcon from '@iconify/icons-material-symbols/e911-emergency-outline';
import gasIcon from '@iconify/icons-material-symbols/local-fire-department-outline';
import rdpIcon from '@iconify/icons-material-symbols/speed-outline';
import bspMariIcon from '@iconify/icons-material-symbols/swap-horiz';
import cspIcon from '@iconify/icons-material-symbols/swap-horizontal-circle-outline';
import { ProductKind, ProvidedService } from '#edsn/api/pie-bff';

export const getProvidedServiceIcon = (input?: ProvidedService) => {
  switch (input) {
    case ProvidedService.RDP:
      return rdpIcon;
    case ProvidedService.CPB:
      return cspIcon;
    case ProvidedService.FCR:
      return bspFrcIcon;
    case ProvidedService.AFRR:
      return bspAffrIcon;
    case ProvidedService.MARI:
      return bspMariIcon;
    case ProvidedService.EMRGP:
      return bspEmrgpIcon;
    default:
      return contractIcon;
  }
};

export const getProductKindIcon = (input?: ProductKind) => {
  switch (input) {
    case ProductKind._23:
      return boltIcon;
    case ProductKind._27:
      return gasIcon;
    default:
      return null;
  }
};

export const getIconByText = (input?: string) => {
  if (typeof input !== 'string') {
    return null;
  }

  const lowerText = input.toLowerCase();
  const hasElectricity = lowerText.includes('elektriciteit');
  const hasGas = lowerText.includes('gas');

  if (hasElectricity && !hasGas) return boltIcon;
  if (hasGas && !hasElectricity) return gasIcon;
};
