import type { SelectDto } from '#edsn/api/pie-bff';
import { createSortingAtom } from './createSortingAtom';
import type { DefaultSorting } from './defaultSorting';

// dto aanpassen naar interface marketparties

export const defaultMarketPartySortingAtom: DefaultSorting<SelectDto> = {
  desc: false,
  id: 'label',
};

export const marketPartySortingAtom = createSortingAtom({ defaultSorting: defaultMarketPartySortingAtom });
