import { useTranslation } from 'react-i18next';
import { ProvidedService } from '#edsn/api/pie-bff';

interface LicenseNameProps {
  name?: string | null;
  providedService?: ProvidedService;
}

export const LicenseName = ({ name, providedService }: LicenseNameProps) => {
  const { t } = useTranslation('marketParty');

  switch (providedService) {
    case ProvidedService.FCR:
      return t('license.provided_service.FCR');
    case ProvidedService.AFRR:
      return t('license.provided_service.AFRR');
    case ProvidedService.MARI:
      return t('license.provided_service.MARI');
    case ProvidedService.EMRGP:
      return t('license.provided_service.EMRGP');
    default:
      return name;
  }
};
