import type { PropsWithChildren } from 'react';
import { cn } from '#pie/utils/TailwindUtils';

export interface DescriptionListProps extends PropsWithChildren {
  className?: string;
  variant?: 'horizontal' | 'grid';
}

export function DescriptionList({ className, children, variant = 'horizontal' }: DescriptionListProps) {
  return (
    <dl
      className={cn(
        'text-primary-dark',
        variant === 'horizontal'
          ? 'grid grid-cols-1 gap-x-8 gap-y-6 xl:grid-cols-[min-content,_minmax(9rem,_1fr)]'
          : 'grid grid-cols-1 gap-4 lg:grid-cols-[400px,_2fr]',
        className
      )}
    >
      {children}
    </dl>
  );
}

export { DescriptionListItem } from './description-list-item/DescriptionListItem';
