import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { Icon } from '../icon/Icon';
import { Tooltip } from '../tooltip/Tooltip';
import type { ComponentProps, ReactNode } from 'react';

interface Props extends Omit<ComponentProps<typeof Tooltip>, 'children' | 'message'> {
  children: ReactNode;
  className?: string;
  size?: 12 | 16 | 20 | 24 | 32 | 48;
}

export const InfoTooltip = ({ children, className, size = 16 }: Props) => (
  <Tooltip message={children}>
    <Icon className={`${className} align-top`} icon={infoIcon} size={size} />
  </Tooltip>
);
