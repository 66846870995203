import { useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ContactDto } from '#edsn/api/pie-bff';
import { getManagementContact } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { UpdateContactModal } from '#pie/components/contact-modal/UpdateContactModal';
import { DataTable } from '#pie/components/data-table/DataTable';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { RemoveCprContactpersonButton } from '#pie/components/remove-cpr-contactperson-button/RemoveCprContactpersonButton';
import { Stack } from '#pie/components/stack/Stack';
import { useDataTable } from '#pie/hooks/useDataTable';
import { contactSortingAtom } from '#pie/stores/contact';
import { searchAtom } from '#pie/stores/search';
import { emptyFilterAtom } from '#pie/stores/searchOnlyFilter';

export const ContactPersonManagement = () => {
  const { t } = useTranslation();

  const [activePerson, setActivePerson] = useState<ContactDto | undefined>(undefined);

  const tableProps = useDataTable({
    columns: helper => [
      helper.accessor('fullName', {
        header: t('common.name'),
        meta: { columnWidth: 200 },
      }),
      helper.accessor('email', {
        header: t('common.email'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('phone', {
        cell: info => (
          <div className="flex">
            {info.getValue()}
            <div
              className="absolute inset-0 cursor-pointer"
              onClick={() => setActivePerson(info.row.original)}
              aria-label={info.row.original.fullName}
            />
            {!info.row.original.logOnEnabled && <RemoveCprContactpersonButton data={info.row.original} />}
          </div>
        ),
        header: t('common.phone'),
      }),
    ],
    dateKeys: [],
    filtersAtom: emptyFilterAtom,
    query: getManagementContact,
    sortingAtom: contactSortingAtom,
  });

  const [search, setSearch] = useAtom(searchAtom);

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <header className="px-3" role="toolbar">
            <Stack direction="row" gap="md" className="items-center">
              <InputSearch
                value={search || ''}
                onChange={e => setSearch(e.target.value)}
                placeholder={t('common.search.placeholder')}
                onClear={() => setSearch('')}
              />
            </Stack>
          </header>
          <DataTable {...tableProps} idKey="id" />
        </Stack>
      </Card>
      <UpdateContactModal data={activePerson} onClose={() => setActivePerson(undefined)} />
    </section>
  );
};
