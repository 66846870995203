import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { HeadingByLicense } from '#pie/components/mpr-license/HeadingByLicense';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';

interface MarketPartyLicenseProps {
  marketParty: MarketPartyRegistrationDto;
}

export const MarketPartyLicense = ({ marketParty }: MarketPartyLicenseProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="lg"
      heading={
        <Stack direction="row" gap="sm" align="center">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('license.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="authorization tooltip" size={20}>
            {t('license.title.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      headingUnderline={false}
    >
      {marketParty.licenses?.map((license, index) => (
        <Stack gap="sm" className="mb-4">
          <Card
            key={index}
            size="md"
            heading={
              <HeadingByLicense
                name={license.name}
                providedService={license.providedServices?.length === 1 ? license.providedServices[0] : undefined}
              />
            }
            shadow={false}
          >
            <DescriptionList variant="grid">
              <DescriptionListItem direction="vertical" title={t('license.service')}>
                {license.providedServices?.length
                  ? license.providedServices
                      .map(service =>
                        t(`provided_service.abbreviations_${service}`, {
                          defaultValue: '',
                        })
                      )
                      .filter(Boolean)
                      .join(', ')
                  : '-'}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('startdate')}>
                {license.startDate && getFormattedDate(new Date(license.startDate), false)}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('enddate')}>
                {(license.endDate && getFormattedDate(new Date(license.endDate), false)) || 'Onbepaald'}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('license.reference')}>
                {license.referenceDescription}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('license.assignment_authority')}>
                {license.assigmentAuthorityName}
              </DescriptionListItem>
            </DescriptionList>
          </Card>
        </Stack>
      ))}
    </Card>
  );
};
