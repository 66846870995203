import { createColumnHelper, functionalUpdate } from '@tanstack/react-table';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { MarketPartyDto } from '#edsn/api/pie-bff';
import { useGetMarketpartyRegistryInvoiceContactMarketParties } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { InputSearch } from '#pie/components/input-search/InputSearch';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Table } from '#pie/components/table/Table';
import { Text } from '#pie/components/text/Text';
import { marketPartySortingAtom } from '#pie/stores/marketParties';
import { searchAtom } from '#pie/stores/search';

export const InvoiceContactRegister = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetMarketpartyRegistryInvoiceContactMarketParties();
  const helper = useMemo(() => createColumnHelper<MarketPartyDto>(), []);
  const [sorting, setSorting] = useAtom(marketPartySortingAtom);
  const [search, setSearch] = useAtom(searchAtom);

  const marketParties = data?.items ?? [];
  const filteredMarketParties = search
    ? marketParties.filter(m => m.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : marketParties;

  return (
    <>
      <PageHead title={t('invoice_contact_register.title')} />
      <Page>
        <PageHeader title={t('invoice_contact_register.title')} className="mb-8" />
        <section className="flex items-start gap-6">
          <Card className="flex-1 overflow-x-hidden p-4">
            <Stack gap="md">
              <header className="px-3" role="toolbar">
                <Stack direction="row" gap="md" className="items-center">
                  <InputSearch
                    value={search || ''}
                    onChange={e => setSearch(e.target.value)}
                    placeholder={t('common.search.placeholder')}
                    onClear={() => setSearch('')}
                  />
                </Stack>
              </header>

              {!isLoading && !filteredMarketParties?.length ? (
                <Text className="p-3">{t('common.no_results')}</Text>
              ) : (
                <Table
                  rowLinks={row => ({
                    'aria-label': row.original.name,
                    to: `${row.original.mprId}/${row.original.mprIdOrganization}`,
                  })}
                  columns={[
                    helper.accessor('name', {
                      cell: info => (
                        <Link
                          className="text-primary link"
                          to={`${info.row.original.mprId}/${info.row.original.mprIdOrganization}`}
                          aria-label={info.getValue()}
                        >
                          {info.getValue()}
                        </Link>
                      ),
                      header: t('invoice_contact_register.columns_id'),
                    }),
                  ]}
                  sortDescFirst
                  getRowId={m => m.mprId}
                  data={filteredMarketParties}
                  onSortingChange={sortingUpdater => {
                    const newSortVal = functionalUpdate(sortingUpdater, sorting);
                    setSorting(newSortVal);
                  }}
                  pagination={{ pageIndex: 0, pageSize: 20 }}
                  sorting={sorting}
                  isLoading={isLoading}
                />
              )}
            </Stack>
          </Card>
        </section>
      </Page>
    </>
  );
};
