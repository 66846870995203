import type { MarketPartyRegistrationDto } from '../generated/model';

export const createMarketPartyInformation = (
  overrides: Partial<MarketPartyRegistrationDto> = {}
): MarketPartyRegistrationDto => ({
  chamberOfCommerceNumber: '12345678',
  city: 'Amsterdam',
  country: 'NL',
  houseNumber: 123,
  invoiceContacts: [
    {
      addressExtensionDescription: 't.a.v. Financiële Administratie',
      electronicAddressPrimary: 'info@testcase.nl',
      subjectDescription: 'Verrekenen Meetfouten GV',
    },
  ],
  licenses: [
    {
      assigmentAuthorityName: 'ACM',
      endDate: '2025-12-31T00:00:00Z',
      name: 'Leveringsvergunning elektriciteit',
      providedServices: ['LVR'],
      referenceDescription: 'ACM/UIT/583999',
      startDate: '2020-01-01T00:00:00Z',
    },
    {
      assigmentAuthorityName: 'GTS',
      endDate: '',
      name: 'Leveringsvergunning gas',
      providedServices: ['LVR'],
      referenceDescription: 'DVT/UIT/583999',
      startDate: '2025-06-01T00:00:00Z',
    },
  ],
  marketParticipantEanCode: '8712423010208',
  marketParticipantStartDate: '2020-01-01T00:00:00Z',
  marketRole: 'DDQ',
  preferences: [
    {
      eanCode: '8718222345667',
      endDate: '2022-01-01T00:00:00Z',
      productName: '_23',
      startDate: '2020-01-01T00:00:00Z',
    },
    {
      eanCode: '8712423010208',
      endDate: '',
      productName: '_27',
      startDate: '2021-01-01T00:00:00Z',
    },
  ],
  serviceCategories: [
    {
      marketSegment: 'SML',
      product: '_23',
      providedService: 'LVR',
    },
    {
      marketSegment: 'LRG',
      product: '_23',
      providedService: 'AFD',
    },
    {
      product: '_27',
      providedService: 'LVR',
    },
  ],
  status: 'ACT',
  statutoryName: 'Energy Trading Company B.V.',
  streetName: 'Energy Street',
  subUnits: [
    {
      eanCode: '8718222345667',
      endDate: '2023-06-15T00:00:00Z',
      iban: 'NL12ABNA0123456789',
      name: 'Eneco B.B.',
      startDate: '2020-01-01T00:00:00Z',
      vat: '123456789B00',
    },
    {
      eanCode: '4823396406869',
      endDate: '',
      iban: 'NL11ABNA0987654321',
      name: 'Eneco B.V. Stroom Nieuw',
      startDate: '2020-03-01T00:00:00Z',
      vat: '987654321B01',
    },
    {
      eanCode: '8707974981756',
      endDate: '',
      iban: 'NL10ABNA0135792468',
      name: 'Eneco B.V. Gas Nieuw',
      startDate: '2025-10-01T00:00:00Z',
      vat: '456789123B02',
    },
  ],
  zipCode: '1234 AB',
  ...overrides,
});
