import { OidcSecure } from '@axa-fr/react-oidc';
import { type PropsWithChildren, lazy } from 'react';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { AuthorizationRoles } from '#edsn/api/pie-bff';
import { hasRole } from '#edsn/auth';
import { OrganisationProvider, useCurrentOrganisation } from './auth';
import { Account } from './pages/account/Account';
import { AuthError } from './pages/auth-error/AuthError';
import { AuthExpired } from './pages/auth-expired/AuthExpired';
import { ClearCache } from './pages/clear-cache/ClearCache';
import { ConnectionInformation } from './pages/connection-information/ConnectionInformation';
import { ConnectionInformationDetail } from './pages/connection-information/ConnectionInformationDetail';
import { ContactGroupDetail } from './pages/contact-person-register/ContactGroupDetail';
import { ContactPersonRegister } from './pages/contact-person-register/ContactPersonRegister';
import { ContactPersonRegisterOrganisationDetail } from './pages/contact-person-register/OrganisationDetail';
import { DataServices } from './pages/data-services/DataServices';
import { DataServicesForm } from './pages/data-services/DataServicesForm';
import { ErrorBoundary } from './pages/error-boundary/ErrorBoundary';
import { ErrorPage } from './pages/error-page/ErrorPage';
import { Home } from './pages/home/Home';
import { InvoiceContactRegister } from './pages/invoice-contact-register/InvoiceContactRegister';
import { InvoiceContactRegisterDetail } from './pages/invoice-contact-register/InvoiceContactRegisterDetail';
import { KnowledgeBase } from './pages/knowledge-base/KnowledgeBase';
import { KnowledgeBaseDetail } from './pages/knowledge-base-detail/KnowledgeBaseDetail';
import { ContactGroupManagementDetail } from './pages/organisation-management/cpr/ContactGroupManagementDetail';
import { OrganisationManagement } from './pages/organisation-management/OrganisationManagement';
import { TeamDetail } from './pages/organisation-management/teams/TeamDetail';
import { CreateOrganisationUser } from './pages/organisation-management/users/create/CreateOrganisationUser';
import { UpdateOrganisationUser } from './pages/organisation-management/users/create/UpdateOrganisationUser';
import { ReleaseNotes } from './pages/release-notes/ReleaseNotes';
import { SurveyDialogue } from './pages/survey-dialogue/SurveyDialogue';
import { SurveyDialogueDetail } from './pages/survey-dialogue-detail/SurveyDialogueDetail';
import { TicketCreateEan } from './pages/ticket-create/ean/TicketCreateEan';
import { TicketCreateNonConformity } from './pages/ticket-create/non-conformity/TicketCreateNonConformity';
import { TicketCreateOtherDetails } from './pages/ticket-create/other/TicketCreateOtherDetails';
import { TicketCreateSelectType } from './pages/ticket-create/TicketCreateSelectType';

const Layout = lazy(() => import('./components/layout/Layout').then(module => ({ default: module.Layout })));
const NewsArchive = lazy(() =>
  import('./pages/news-archive/NewsArchive').then(module => ({ default: module.NewsArchive }))
);
const NewsDetail = lazy(() =>
  import('./pages/news-detail/NewsDetail').then(module => ({ default: module.NewsDetail }))
);
const Tickets = lazy(() => import('./pages/tickets/Tickets').then(module => ({ default: module.Tickets })));
const TicketDetail = lazy(() =>
  import('./pages/ticket-detail/TicketDetail').then(module => ({ default: module.TicketDetail }))
);

const AuthGuard = ({
  children,
  roles = [],
  marketRoles = [],
}: { roles?: AuthorizationRoles[]; marketRoles?: string[] } & PropsWithChildren) => {
  const { currentAccount } = useCurrentOrganisation();

  return currentAccount && hasRole(roles, currentAccount.roles) && hasRole(marketRoles, [currentAccount.marketRole]) ? (
    children
  ) : (
    <ErrorPage heading="Geen toegang" message="Je hebt niet de juiste rechten om deze pagina te bekijken." />
  );
};

export const createRouter = () =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path=""
        element={
          <OidcSecure>
            <OrganisationProvider>
              <Outlet />
            </OrganisationProvider>
          </OidcSecure>
        }
        errorElement={<ErrorBoundary noLayout />}
      >
        <Route path="" errorElement={<ErrorBoundary />}>
          <Route element={null} path="oauth/handler" />
          <Route element={null} path="oauth/silent-callback" />
          <Route element={<Layout />} path="">
            <Route index element={<Home />} />
            <Route
              element={
                <AuthGuard roles={['KnowledgeArticles']}>
                  <Outlet />
                </AuthGuard>
              }
              path="kennisbank"
            >
              <Route index element={<KnowledgeBase />} />
              <Route element={<KnowledgeBaseDetail />} path=":id" />
            </Route>
            <Route
              element={
                <AuthGuard roles={['DataRequest']}>
                  <Outlet />
                </AuthGuard>
              }
              path="datadiensten"
            >
              <Route index element={<DataServices />} />
              <Route element={<DataServicesForm />} path="verzoeken" />
            </Route>
            <Route path="nieuws">
              <Route element={<NewsArchive />} index />
              <Route element={<NewsDetail />} path=":slug" />
            </Route>
            <Route
              element={
                <AuthGuard roles={['Tickets']}>
                  <Outlet />
                </AuthGuard>
              }
              path="tickets"
            >
              <Route element={<Tickets />} index />
              <Route path="nieuw">
                <Route element={<TicketCreateSelectType />} index />
                <Route path="ean" element={<TicketCreateEan />} />
                <Route
                  path="non-conformity"
                  element={
                    <AuthGuard marketRoles={['MV']}>
                      <TicketCreateNonConformity />
                    </AuthGuard>
                  }
                />
                <Route element={<TicketCreateOtherDetails />} path="other" />
              </Route>
              <Route element={<TicketDetail />} path=":id" />
            </Route>
            <Route
              element={
                <AuthGuard roles={['Tickets']}>
                  <Outlet />
                </AuthGuard>
              }
              path="ticketfeedback"
            >
              <Route element={<SurveyDialogue />} index />
              <Route element={<SurveyDialogueDetail />} path=":id" />
            </Route>
            <Route path="contactpersonenregister" element={<Outlet />}>
              <Route
                path=""
                element={
                  <AuthGuard roles={[AuthorizationRoles.CprRead]}>
                    <Outlet />
                  </AuthGuard>
                }
              >
                <Route index element={<Navigate to="contact" />} />
                <Route element={<ContactPersonRegister />} path="contact" />
                <Route element={<ContactPersonRegister />} path="organisatie" />
                <Route element={<ContactPersonRegisterOrganisationDetail />} path="organisatie/:organisationId" />
                <Route element={<ContactPersonRegister />} path="contactgroep" />
                <Route element={<ContactGroupDetail />} path="contactgroep/:contactGroupId" />
              </Route>
            </Route>

            <Route path="aansluitingsinformatie" element={<ConnectionInformation />}>
              <Route element={<ConnectionInformationDetail />} path=":ean" />
            </Route>

            <Route path="beheer" element={<Outlet />}>
              <Route index element={<OrganisationManagement />} />
              <Route
                path=""
                element={
                  <AuthGuard roles={[AuthorizationRoles.OrganisationAdmin]}>
                    <Outlet />
                  </AuthGuard>
                }
              >
                <Route element={<OrganisationManagement />} path="marktpartijregistratie" />
              </Route>
              <Route
                path=""
                element={
                  <AuthGuard roles={[AuthorizationRoles.SuperUser]}>
                    <Outlet />
                  </AuthGuard>
                }
              >
                <Route element={<OrganisationManagement />} path="gebruikersbeheer" />
                <Route element={<CreateOrganisationUser />} path="gebruikersbeheer/nieuw" />
                <Route element={<UpdateOrganisationUser />} path="gebruikersbeheer/:userId" />
                <Route element={<OrganisationManagement />} path="teamsbeheer" />
                <Route element={<OrganisationManagement />} path="notificaties" />
                <Route element={<TeamDetail />} path="teamsbeheer/:teamId" />
              </Route>
              <Route
                path=""
                element={
                  <AuthGuard roles={[AuthorizationRoles.CprAdmin]}>
                    <Outlet />
                  </AuthGuard>
                }
              >
                <Route element={<OrganisationManagement />} path="contactgroepenbeheer" />
                <Route element={<ContactGroupManagementDetail />} path="contactgroepenbeheer/:contactGroupId" />
                <Route element={<OrganisationManagement />} path="contactpersonenbeheer" />
              </Route>
            </Route>

            <Route
              element={
                <AuthGuard
                  roles={[AuthorizationRoles.CprRead, AuthorizationRoles.SuperUser]}
                  marketRoles={['LV', 'GDS', 'RNB', 'LNB']}
                >
                  <Outlet />
                </AuthGuard>
              }
              path="factuurcontactregister"
            >
              <Route index element={<InvoiceContactRegister />} />
              <Route element={<InvoiceContactRegisterDetail />} path=":marketPartyId/:organisationId" />
            </Route>
            <Route element={<Account />} path="account" />
            <Route element={<ReleaseNotes />} path="release-notes" />
            <Route element={<ClearCache />} path="clear-cache" />
            <Route element={<AuthError />} path="/auth-error" />
            <Route element={<AuthExpired />} path="/auth-expired" />
          </Route>
        </Route>
      </Route>
    )
  );
