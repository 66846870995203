import { useMemo } from 'react';
import { useCurrentOrganisation } from '#pie/auth';
import { isMenuItemAllowed, menu } from '#pie/components/layout/sidebar/menu/menu';
import { useStoredParams } from '#pie/stores/searchParams';

export const useProtectedMenu = () => {
  const { currentAccount } = useCurrentOrganisation();
  const storedTicketFilterParams = useStoredParams('/tickets');
  const computedMenu = menu({
    ticketFilterParams: storedTicketFilterParams,
  });

  const bottomMenu = useMemo(
    () => computedMenu.bottom.filter(menuItem => isMenuItemAllowed(menuItem, currentAccount)),
    [computedMenu.bottom, currentAccount?.roles]
  );

  const mainMenu = useMemo(
    () => computedMenu.main.filter(menuItem => isMenuItemAllowed(menuItem, currentAccount)),
    [computedMenu.main, currentAccount?.roles]
  );

  return { bottomMenu, mainMenu };
};
