import he from 'he';
import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionList, DescriptionListItem } from '#pie/components/description-list/DescriptionList';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';

interface MarketPartyInvoiceContacProps {
  marketParty: MarketPartyRegistrationDto;
}

const decodeHtmlEntities = (text: string | null | undefined) => {
  return text ? he.decode(text) : '';
};

export const MarketPartyInvoiceContact = ({ marketParty }: MarketPartyInvoiceContacProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="lg"
      heading={
        <Stack direction="row" gap="sm" align="center">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('invoice_contact.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="marketparty information is from the kvk" size={20}>
            {t('invoice_contact.title.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      shadow={true}
      headingUnderline={false}
    >
      {marketParty.invoiceContacts?.map((ic, index) => (
        <Stack gap="sm" className="mb-4">
          <Card
            key={index}
            size="md"
            heading={
              <Stack direction="row" align="center" className="justify-between">
                <Text as="h2" variant="base" className="text-primary-dark font-bold">
                  {ic.subjectDescription}
                </Text>
              </Stack>
            }
            shadow={false}
          >
            <DescriptionList variant="grid">
              <DescriptionListItem title={t('invoice_contact.address')} direction="vertical">
                <Text>{decodeHtmlEntities(marketParty.statutoryName)}</Text>
                <Text>{decodeHtmlEntities(ic.addressExtensionDescription)}</Text>
                <Text>
                  {decodeHtmlEntities(marketParty.streetName)} {marketParty.houseNumber}
                  {marketParty.houseNumberExtension}
                </Text>
                <Text>
                  {marketParty.zipCode} {''}
                  {decodeHtmlEntities(marketParty.city)}
                </Text>
                <Text>{decodeHtmlEntities(marketParty.country)}</Text>
              </DescriptionListItem>
              <DescriptionListItem title={t('invoice_contact.email')} direction="vertical">
                {ic.electronicAddressPrimary}
              </DescriptionListItem>
            </DescriptionList>
          </Card>
        </Stack>
      ))}
    </Card>
  );
};
