import PersonOffIcon from '@iconify/icons-material-symbols/block';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getContactDetails, getGetContactDetailsIdQueryKey, useDeleteContactDetailsId } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { useToast } from '#pie/components/toast/ToastContext';

interface Props {
  userId: string;
  disabled?: boolean;
}

export const DeleteOrganisationUserModal = ({ userId, disabled }: Props) => {
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { t: tcom } = useTranslation();
  const { t: tom } = useTranslation('organisationManagement');
  const navigate = useNavigate();

  const { mutate, isLoading } = useDeleteContactDetailsId({
    mutation: {
      onError: () => {
        addToast({
          message: tom('toast.error.message.user_cannot_be_deleted'),
          title: tom('toast.error.title.delete_user'),
          type: 'error',
        });
      },
      onSuccess: () => {
        addToast({
          message: tcom('common.deleted_user'),
          title: tom('toast.succes.title.user_deleted'),
          type: 'success',
        });
        queryClient.invalidateQueries(getGetContactDetailsIdQueryKey(userId));
        queryClient.invalidateQueries([getContactDetails.name]);
        navigate('/beheer/gebruikersbeheer/');
      },
    },
  });

  const handleOnClick = () => {
    mutate({ id: userId });
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen} title={tcom('common.delete_user')}>
        <Stack gap="md">
          {tom('delete.user.message')}
          <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
            <Button variant="ghost" onClick={() => setOpen(false)}>
              {tcom('common.button.cancel')}
            </Button>
            <Button variant="danger" onClick={handleOnClick}>
              {tcom('common.delete_user')}
            </Button>
          </div>
        </Stack>
      </Dialog>
      <Button
        isLoading={isLoading}
        iconStart={PersonOffIcon}
        onClick={() => setOpen(true)}
        variant="ghost"
        isDisabled={disabled}
      >
        {tcom('common.delete_user')}
      </Button>
    </>
  );
};
