import IconExpandMore from '@iconify/icons-material-symbols/expand-more-sharp';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import type { AuthorizationRoles, MarketRole } from '#edsn/api/pie-bff';
import { BetaDialog } from '../beta-dialog/BetaDialog';
import { isLinkBeta } from './menu';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { PointerEvent, ReactNode } from 'react';
import { Icon, type IconProps } from '#pie/components/icon/Icon';
import { Text } from '#pie/components/text/Text';
import { tv } from '#pie/utils/TailwindUtils';

export interface Menu {
  exactMatch?: boolean;
  to: string;
  label: string;
  description?: string;
  icon: IconProps['icon'];
  children?: { to: string; label: string }[];
  allowedRoles?: AuthorizationRoles[];
  allowedMarketRoles?: MarketRole[];
  adornment?: (isOpen?: boolean) => ReactNode;
}

interface SidebarMenuProps extends VariantProps<typeof styles> {
  menu: Menu[];
  className?: string;
}

export const SidebarMenu = ({ menu, isOpen, className }: SidebarMenuProps) => {
  const s = styles({ isOpen });
  const [value, setValue] = useState<string>();
  const { pathname } = useLocation();
  const [beta, setBeta] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      menu.forEach(item => {
        if (matchPath({ end: false, path: item.to }, pathname)) {
          setValue(item.to);
        }
      });
    }
  }, [isOpen, menu, pathname]);

  useEffect(() => {
    if (!isOpen) {
      setValue(undefined);
    }
  }, [isOpen]);

  const handlePrevent = (event: Event | PointerEvent) => {
    if (isOpen) {
      event.preventDefault();
    }
  };

  return (
    <>
      <NavigationMenu.Root
        value={value}
        onValueChange={setValue}
        orientation={isOpen ? 'horizontal' : 'vertical'}
        className={s.base({ className })}
      >
        <NavigationMenu.List>
          {menu.map((item, idx) => {
            const isActive = !!matchPath({ end: item.exactMatch, path: item.to }, pathname);

            // Render dropdown item when in beta and item has children
            if (item.children) {
              return (
                <NavigationMenu.Item key={idx} value={item.to} className="relative">
                  <NavigationMenu.Trigger onPointerMove={handlePrevent} onPointerLeave={handlePrevent} asChild>
                    <button className={s.item({ isActive })} aria-label={item.label}>
                      <Icon icon={item.icon} />

                      {isOpen && item.label}

                      {isLinkBeta(item.to) && (
                        <Text variant="xs" className={s.beta()}>
                          {isOpen && 'Beta'}
                        </Text>
                      )}

                      {isOpen && <Icon icon={IconExpandMore} className="ml-auto text-white" size={16} />}
                    </button>
                  </NavigationMenu.Trigger>

                  <NavigationMenu.Content
                    onPointerMove={handlePrevent}
                    onPointerLeave={handlePrevent}
                    onPointerDownOutside={handlePrevent}
                    onEscapeKeyDown={handlePrevent}
                    onFocusOutside={handlePrevent}
                    onInteractOutside={handlePrevent}
                    className={s.dropdown()}
                  >
                    <NavigationMenu.Sub>
                      <NavigationMenu.List>
                        {item.children.map((child, idx) => {
                          const isActive = !!matchPath({ path: child.to }, pathname);

                          return (
                            <NavigationMenu.Item key={idx}>
                              <NavigationMenu.Link asChild>
                                {isLinkBeta(child.to) ? (
                                  <Link to={child.to} className={s.dropdownItem({ isActive })}>
                                    {child.label}
                                  </Link>
                                ) : (
                                  <button onClick={() => setBeta(item.to)} className={s.dropdownItem({ isActive })}>
                                    {child.label}
                                  </button>
                                )}
                              </NavigationMenu.Link>
                            </NavigationMenu.Item>
                          );
                        })}
                      </NavigationMenu.List>

                      <NavigationMenu.Viewport />
                    </NavigationMenu.Sub>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              );
            }

            // Render simple item when in beta and item has no children
            return (
              <NavigationMenu.Item key={idx} value={item.to} className="relative">
                <NavigationMenu.Link asChild>
                  {isLinkBeta(item.to) ? (
                    <Link to={item.to} className={s.item({ isActive })} aria-label={item.label}>
                      <Icon icon={item.icon} className="shrink-0" />
                      {isOpen && item.label}

                      {!!item.adornment && item.adornment(isOpen)}

                      <Text variant="xs" className={s.beta()}>
                        {isOpen && 'Beta'}
                      </Text>
                    </Link>
                  ) : (
                    <button onClick={() => setBeta(item.to)} className={s.item({ isActive })} aria-label={item.label}>
                      <Icon icon={item.icon} className="shrink-0" />

                      {isOpen && item.label}
                    </button>
                  )}
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            );
          })}
        </NavigationMenu.List>
      </NavigationMenu.Root>

      {beta && <BetaDialog open={!!beta} onOpenChange={() => setBeta(undefined)} to={beta} />}
    </>
  );
};

export const styles = tv({
  slots: {
    adornment: '',
    base: 'flex flex-col gap-3 mt-6',
    beta: 'bg-success text-white uppercase',
    dropdown: '',
    dropdownItem: 'flex text-sm hover:underline focus:underline focus:outline-none',
    item: 'text-left flex gap-3 w-full items-center text-paragraph text-white py-3 hover:bg-primary-dark transition-colors focus:outline-none focus:bg-primary-dark data-[state=open]:bg-primary-dark px-5',
  },

  variants: {
    isActive: {
      false: {
        item: '',
      },

      true: {
        dropdownItem: 'underline',
        item: 'bg-primary-dark',
      },
    },

    isOpen: {
      false: {
        adornment: 'w-3 h-3 absolute top-0 right-3',
        beta: 'w-3 h-3 rounded-full absolute right-3 top-7',
        dropdown: 'absolute left-full top-0 w-56 bg-white shadow-lg',
        dropdownItem: 'whitespace-nowrap px-7 py-3',
        item: 'justify-center',
      },
      true: {
        adornment: '',
        beta: 'ml-auto px-2',
        dropdown: 'my-3',
        dropdownItem: 'pl-7 text-white py-3 ml-8',
        item: '',
      },
    },
  },
});
