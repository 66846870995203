/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type ProductKind = (typeof ProductKind)[keyof typeof ProductKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductKind = {
  _23: '_23',
  _27: '_27',
} as const;
