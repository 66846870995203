import { useTranslation } from 'react-i18next';
import type { MarketPartyRegistrationDto } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { DescriptionListItem } from '#pie/components/description-list/description-list-item/DescriptionListItem';
import { DescriptionList } from '#pie/components/description-list/DescriptionList';
import { Icon } from '#pie/components/icon/Icon';
import { InfoTooltip } from '#pie/components/info-tooltip/InfoTooltip';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { getFormattedDate } from '#pie/i18n/dateFormats';
import { getProductKindIcon } from '#pie/utils/textToIcon';

interface MarketPartyPreferredPartiesProps {
  marketParty: MarketPartyRegistrationDto;
}

export const MarketPartyPreferredParties = ({ marketParty }: MarketPartyPreferredPartiesProps) => {
  const { t } = useTranslation('marketParty');

  return (
    <Card
      size="lg"
      heading={
        <Stack direction="row" gap="sm">
          <Text as="h2" variant="h6" className="text-primary-dark">
            {t('preferred_party.title')}
          </Text>
          <InfoTooltip className="text-primary-dark" aria-label="preferred party tooltip" size={20}>
            {t('preferred_party.title.tooltip')}
          </InfoTooltip>
        </Stack>
      }
      headingUnderline={false}
    >
      {marketParty.preferences?.map((preference, index) => (
        <Stack gap="sm" className="mb-4">
          <Card
            key={index}
            size="md"
            heading={
              <Stack direction="row" gap="xs" align="center">
                {getProductKindIcon(preference.productName) && (
                  <Icon icon={getProductKindIcon(preference.productName)!} size={24} className="text-primary-dark" />
                )}
                <Text as="h2" variant="base" className="text-primary-dark font-bold">
                  {t(`marketParty.product.${preference.productName}`, { defaultValue: '' })}
                </Text>
              </Stack>
            }
            shadow={false}
          >
            <DescriptionList variant="grid">
              <DescriptionListItem direction="vertical" title={t('preferred_party.eancode')}>
                {preference.eanCode}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('startdate')}>
                {preference.startDate && getFormattedDate(new Date(preference.startDate), false)}
              </DescriptionListItem>
              <DescriptionListItem direction="vertical" title={t('enddate')}>
                {(preference.endDate && getFormattedDate(new Date(preference.endDate), false)) || 'Onbepaald'}
              </DescriptionListItem>
            </DescriptionList>
          </Card>
        </Stack>
      ))}
    </Card>
  );
};
