import { User } from 'oidc-client-ts';
import { json, redirect } from 'react-router-dom';
import { fetchOptions } from '#edsn/shared';
import { getOidcStorage } from './AuthProvider';
import type { AuthProviderProps } from 'react-oidc-context';

// Loader for routes that need authentication
export function authLoader(
  oidcConfig: AuthProviderProps & { idp_uri?: string },
  loginPath = '/',
  roles: string[] = [],
  defaultRedirectPath = ''
) {
  return async function ({ request }: { request: Request }): Promise<Response | null> {
    const oidcStorage = getOidcStorage(oidcConfig);

    // Register function to get the bearer token in fetchInstance
    // Also happens in AuthProvider but loaders run before anything is rendered
    fetchOptions.getToken = () => {
      const oidcStorage = getOidcStorage(oidcConfig);
      return (oidcStorage && User.fromStorageString(oidcStorage)?.access_token) ?? null;
    };

    // Assume we are authenticated when there is something in storage
    if (!oidcStorage) {
      return redirect(
        `${loginPath}?returnUrl=${encodeURIComponent(request.url.replace(window.location.origin, defaultRedirectPath))}`
      );
    }

    const oidcUser = User.fromStorageString(oidcStorage);
    if (oidcUser.expired) {
      return redirect(
        `${loginPath}?returnUrl=${encodeURIComponent(request.url.replace(window.location.origin, defaultRedirectPath))}`
      );
    }

    const IDP_URI = import.meta.env.VITE_IDP_URI || oidcConfig.idp_uri;
    const user = await fetch(`${IDP_URI}/tokeninfo?access_token=${oidcUser.access_token}`).then(res => res.json());

    if (!hasRole(roles, user.roles)) {
      throw json(
        { message: 'Je hebt geen rechten om deze pagina te bekijken.' },
        { status: 403, statusText: 'Geen toegang' }
      );
    }

    return null;
  };
}

// Loader for routes that need you to be not authenticated
export function nonAuthLoader(oidcConfig: AuthProviderProps, redirectTo = '/') {
  return function () {
    const oidcStorage = getOidcStorage(oidcConfig);
    if (!oidcStorage) {
      return null;
    }
    const oidcUser = User.fromStorageString(oidcStorage);
    if (oidcUser.expired) {
      return null;
    }

    return redirect(redirectTo);
  };
}

/**
 * @returns true when the user has at least one of the provided roles
 */
export function hasRole(roles: string | string[], profileRoles: string[]) {
  const rolesList: string[] = Array.isArray(roles) ? roles : [roles];
  return rolesList.length === 0 || rolesList?.some(role => profileRoles.includes(role));
}
