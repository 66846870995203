import type {
  InvoiceContactDetailsDto,
  InvoiceContactMarketPartyDetailsRequest,
  MarketPartyDto,
  MarketPartyDtoListResultDto,
  MarketRoleType,
} from '../generated/model';

export const createMarketParty = (overrides: Partial<MarketPartyDto> = {}): MarketPartyDto => ({
  mprId: '123',
  mprIdOrganization: '441100',
  name: 'Test Market Party Eneco',
  ...overrides,
});

export const createInvoiceMarketParties = (
  overrides: Partial<MarketPartyDtoListResultDto> = {}
): MarketPartyDtoListResultDto => ({
  items: [
    createMarketParty(),
    createMarketParty({ mprId: '456', mprIdOrganization: '340022', name: 'Test Market Party Liander' }),
    createMarketParty({ mprId: '789', mprIdOrganization: '550044', name: 'Octo Energy' }),
    createMarketParty({ mprId: '025', mprIdOrganization: '020502', name: 'Test Market Party Dextro' }),
    createMarketParty({ mprId: '026', mprIdOrganization: '990033', name: 'Volt Power' }),
  ],
  totalCount: 4,
  ...overrides,
});

export const createInvoiceContactDetails = (
  mprid: string,
  request: InvoiceContactMarketPartyDetailsRequest,
  overrides: Partial<InvoiceContactDetailsDto> = {}
): InvoiceContactDetailsDto[] => {
  const invoiceContacts = {
    '020502': {
      addressExtensionDescription: null,
      city: 'Utrecht',
      country: 'Nederland',
      electronicAddressPrimary: 'contact@dextro.nl',
      houseNumber: 2,
      marketRole: 'DSO' as MarketRoleType,
      mprId: '025',
      registrationNumberOrigin: 'NL',
      statutoryName: 'Dextro B.V.',
      streetName: 'Utrechtseweg',
      subjectDescription: 'Dextro energy',
      zipCode: '0205 MY',
    },
    '340022': {
      addressExtensionDescription: 'Lia van Liander',
      city: 'Arnhem',
      country: 'Nederland',
      electronicAddressPrimary: 'contact@liander.nl',
      houseNumber: 200,
      houseNumberExtension: 'B',
      marketRole: 'DSO' as MarketRoleType,
      mprId: '456',
      registrationNumberOrigin: 'NL',
      statutoryName: 'Liander N.V.',
      streetName: 'Utrechtseweg',
      subjectDescription: 'Liander kosten',
      zipCode: '6812 AR',
    },
    '441100': {
      addressExtensionDescription: 'Ennie van Eneco',
      city: 'Rotterdam',
      country: 'Nederland',
      electronicAddressPrimary: 'contact@eneco.com',
      houseNumber: 100,
      houseNumberExtension: 'A',
      marketRole: 'DDQ' as MarketRoleType,
      mprId: '123',
      registrationNumberOrigin: 'NL',
      statutoryName: 'Eneco B.V.',
      streetName: 'Eneco Straat',
      subjectDescription: 'Verrekenen meetfouten',
      zipCode: '3015 KK',
    },
    '550044': {
      addressExtensionDescription: 'Henry Smith',
      city: 'New Orleans',
      country: '',
      electronicAddressPrimary: 'info@financial.com',
      houseNumber: 100,
      houseNumberExtension: 'A',
      marketRole: 'DDQ' as MarketRoleType,
      mprId: '123',
      registrationNumberOrigin: 'US',
      statutoryName: 'Octo Energy',
      streetName: 'Energy street',
      subjectDescription: 'Money Matters',
      zipCode: '3015 KK',
    },
    '990033': {
      addressExtensionDescription: null,
      city: 'New Orleans',
      country: 'Nederland',
      electronicAddressPrimary: null,
      houseNumber: 100,
      houseNumberExtension: 'A',
      marketRole: 'DDQ' as MarketRoleType,
      mprId: '123',
      registrationNumberOrigin: 'NL',
      statutoryName: 'Volt Power',
      streetName: 'Energy street',
      subjectDescription: 'Verrekenen meetfouten',
      zipCode: '3015 KK',
    },
  };

  return [
    {
      ...invoiceContacts[request.mprIdOrganization as keyof typeof invoiceContacts],
      ...overrides,
    },
  ];
};
